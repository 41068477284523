//global styles
* {
	box-sizing: border-box; }

html {
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%; }


body {
	background: #fafaf3;
	padding: 0;
	margin: 0;
	-moz-font-feature-settings: "liga" on;
	font-size: 16px;
	-moz-osx-font-smoothing: grayscale;
	//letter-spacing: -0.004em
	line-height: 1.66;
	font-family: $secondary-font;
	color: $grey-90;
	width: 100%;
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
	transition: font-size 200ms cubic-bezier(0.4, 0.0, 0.2, 1); }

img {
	max-width: 100%;
	vertical-align: middle; }

//typography
h1, h2, h3 {
	font-family: $primary-font;
	font-weight: 600;
	line-height: 1.1;
	letter-spacing: -0.0125em;
	font-size: 5em;
	margin: 0 0 1rem;
	transition: font-size 200ms cubic-bezier(0.4, 0.0, 0.2, 1); }

h2 {
	color: $darkgrey;
	font-size: 1.1em;
	font-weight: 600;
	letter-spacing: 1px;
	margin-bottom: 0.5rem;
	margin-top: 3em;
	text-transform: uppercase; }

h2 + p {
	margin-top: 1rem; }

h3 {
	font-family: $tertiary-font;
	font-size: 1.76em;
	font-weight: 400; }

a, p.cta, .cta.inline {
	color: $primary;
	font-family: $secondary-font;
	text-decoration: none;
	transition: all 50ms cubic-bezier(0.4, 0.0, 0.2, 1);
	&:hover {
		border-bottom: 1px dotted $primary;
		color: $secondary; }
	&:focus {
		border-color: transparent;
		outline: 2px dotted $primary;
		outline-offset: 2px; } }


a.case {
	border-bottom: none;
	color: inherit;
	font-family: inherit;
	&:hover {
		color: inherit; }
	&:focus {
		color: inherit;
		background: none; } }

a {
	display: inline; }

.cta.inline {
	background: none;
	border: 0 none;
	border-bottom: 2px solid $primary;
	box-shadow: none;
	cursor: pointer;
	display: inline;
	font-family: $secondary-font;
	font-weight: 400;
	font-size: inherit;
	letter-spacing: 0;
	padding: 0;
	text-transform: none;
	&:hover {
		background: none; } }

.cta.short {
	display: inline-block; }

p {
	color: $grey-90;
	margin: 0;
	margin-bottom: 1.5em;
	&.cta {
		font-family: $primary-font;
		font-size: 0.88rem;
		font-weight: 700;
		text-transform: uppercase; } }

p, ul {
	--baseline-multiplier: 0.17;
	--x-height-multiplier: 0.375;
	font-size: 1.25rem; }

p.small {
	font-size: 1em; }

ul {
	//margin: 0 0 1em -0.025em
 }	//padding: 0

li {
	padding: 0.25em 0; }

li p {
	margin: 0; }

h3 + p {
	margin-top: 0; }

p + ul {
	margin-top: -1em; }

p + h3 {
	margin-top: 3rem; }

blockquote {
	border-bottom: 1px solid $lightgrey;
	border-top: 1px solid $lightgrey;
	margin: 0 0 2rem;
	padding: 1rem 1rem 1rem 3rem;
	position: relative;
	&:before {
		color: $primary;
		content: open-quote;
		font-family: Georgia, 'Lora', serif;
		font-size: 4em;
		font-weight: 700;
		left: 0;
		line-height: 1;
		position: absolute;
		top: 1rem; }
	max-width: calc(100% - 22vw); }

cite {
	font-size: 1.25em; }

//forms
label {
	font-family: $tertiary-font;
	font-size: 1.76em;
	font-weight: 400;
	margin-right: 0.5em;
	text-align: right; }
input {
	background: $white;
	border: 1px solid $lightgrey;
	color: $charcoal;
	font-family: $primary-font;
	font-size: 1em;
	line-height: 1;
	min-width: 45em;
	padding: 1em; }
textarea {
	background: $white;
	border: 1px solid $lightgrey;
	color: $charcoal;
	font-family: $primary-font;
	font-size: 1em;
	line-height: 1.58;
	min-height: 8em;
	max-width: 45em;
	min-width: 45em;
	padding: 1em; }

video {
	object-fit: cover;
	max-height: 100%;
	max-width: 100%; }

.main-wrapper.text-wrap {
	margin-left: auto;
	margin-right: auto;
	max-width: 45em; }

.video-js {
	height: 100%;
	width: 100vw; }

.vjs-poster {
	background-size: cover; }

.video-js .vjs-big-play-button {
	border-radius: 50%;
	font-size: 6em;
	height: 2em;
	line-height: 2em;
	width: 2em; }

.vjs-big-play-centered .vjs-big-play-button {
	left: 60%; }

.cta {
	white-space: nowrap; }

a.cta, .form-wrapper .cta {
	background-color: $primary;
	border: 0 none;
	border-radius: 3px;
	color: $white;
	cursor: pointer;
	display: block;
	font-family: $primary-font;
	font-size: 1rem;
	font-weight: 700;
	max-width: 100%;
	padding: 0.5em 1.5em;
	position: relative;
	text-align: center;
	&:hover {
		background-color: $secondary;
		border: 0 none;
		color: $white; }
	&:focus {
		background-color: $secondary;
		border: 0 none;
		outline: 2px dotted $primary;
		outline-offset: 4px;
		color: $white; } }

button.cta, .button.cta {
	@extend a.cta;
 }	//border: 0 none
