@media (max-width: 991px) {

	input {
		min-width: 100%;
		max-width: 100%; }
	textarea {
		min-width: 100%;
		max-width: 100%; }

	//global
	.tablet-block, .tablet-block hr, svg.tablet-block {
		display: block; }

	.tablet-flex {
		display: flex; }

	.tablet-auto {
		height: auto; }

	.tablet-hidden {
		display: none; }

	.block-991 {
		display: block; }

	//nav styles
	.shape-left {
		height: 5rem;
		left: -1rem; }

	.shape-right {
		height: 5rem;
		right: -1rem; }

	//top nav
	.case-study-overview, .case-study-info {
		width: 100%; }

	.main-wrapper.flex-row {
		display: block; }

	.main-wrapper {
		margin: inherit 1rem 0;
		padding: 1.5rem;
		width: calc(100% - 3rem); }

	.negative-offset {
		margin-top: -1.5rem; }

	//slideshow
	.inner-media {
		height: auto; }

	.slideshow-dots {
		left: 1rem;
		top: 2rem; }

	//callouts
	.callout p {
		font-size: 1.25em;
		&.small {
			font-size: 1.25em; } }

	.callout-text h2 {
		font-size: 2.5em;
		margin-top: 1rem; }

	.left {
		margin-left: 5vw; }

	.banner.left {
		margin-left: 0; }

	.right .callout-text {
		margin-left: -2em; }

	.callout img, .callout-img {
		max-width: 100%; }

	.callout-text, .split-991.callout img, .split-991 .callout-img {
		max-width: 40vw; }

	.callout img, .split-991 .callout-text {
		max-width: 60vw; }

	.callout.square {
		max-width: 40em; }

	//banners and portfolio page
	.half-w {
		width: 100%;
		.text {
			max-width: 80vw; } }

	.full-h.flex-col {
		height: auto; }


	.banner.wide-wrapper {
		height: 40vh;
		width: 100%; }

	//case study
	.tag-right, .tag-left {
		height: 4rem;
		width: 8rem; }

	.case-study-text h3 {
		//some math - calc(base + (max - min)*(100vw - min screen)/(max screen - min screen))
		font-size: calc(32px + (80 - 32)*(100vw - 767px)/(991 - 767)); }

	.case-study-info .flex-col {
		width: 33.33%; }

	.case-study-info {
		display: flex; }

	//more
	.case-study {
		height: 33vh;
		max-height: 18em; }

	.case-study-text {
		max-width: 100%; }

	.portfolio-wrapper {
		padding: 0;
		width: 100%; }

	.case-studies {
		gap: 1em;
		grid-template-columns: 1fr 1fr;
		margin: 0 1.5em; }

	//cards
	.card {
		margin: 0;
		width: 100%; }

	.tall .background-img {
		height: auto; }

	.text-left, .text-right {
		gap: 1em;
		grid-template-columns: 1fr 1fr;
		padding: 4em 2em; }


	.about-wrapper {
		grid-template-columns: 2fr 1fr; }

	.logo-list {
		gap: 2em;
		grid-template-columns: 1fr 1fr 1fr 1fr; }

	.text-center {
		grid-template-columns: 0 1fr 0; } }
