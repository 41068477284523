.flex-row {
	display: flex;
	justify-content: space-around; }

.case-study-overview {
	padding-right: 1rem;
	width: 66.66%;
	p {
		margin-bottom: 1rem; } }

.case-study-info {
	align-self: flex-start;
	background: $lightgrey;
	margin-bottom: 3rem;
	padding: 2em;
	width: 33.33%;
	&:after {
		clear: both;
		content: "";
		display: table; }
	h2 {
		color: $primary;
		font-size: 0.88em;
		margin-bottom: 0.25em;
		margin-top: 1em;
		text-transform: uppercase; }
	ul {
		font-size: 1em;
		list-style-type: none;
		margin: 0;
		margin-bottom: 1em;
		padding: 0;
		li {
			line-height: 1.1;
			margin: 0; } }
	a {
		width: 100%; }
	.flex-col {
		width: 100%; }
	p {
		font-size: 1em; } }

.stat {
	text-align: center;
	margin-bottom: 2rem;
	padding: 0 0.5em;
	width: 33.33%;
	.large {
		color: $primary;
		font-family: $primary-font;
		//some math - calc(base + (max - min)*(100vw - min screen)/(max screen - min screen))
		//font-size: 2.5em
		font-size: calc(32px + (80 - 64)*(100vw - 768px)/(1920 - 768));
		font-weight: 700;
		margin-bottom: 0;
		margin-top: 1em; } }

iframe {
	border: 0 none; }
