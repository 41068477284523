.experience {
	position: relative;
	transition: all 600ms ease;
	.inner-caption {
		background: none;
		border-top: 1px solid $darkgrey;
		color: $charcoal;
		margin-top: 2rem;
		padding: 1rem 0 0; }
	.inner-media {
		background: $white;
		height: auto; }
	&:after {
		clear: both;
		content: "";
		display: table; }
	p {
		margin: 0; } }

.experience:before {
	//content: url('./../assets/svg/expand.svg')
	cursor: pointer;
	height: 2rem;
	position: absolute;
	right: 2.5rem;
	top: 2.5rem;
	width: 2rem;
	z-index: 2; }

.main-wrapper.experience {
	background: $lightgrey;
	margin-bottom: 3rem;
	padding: 2rem; }

.experience .cta {
	border: 0 none;
	display: inline-block;
	margin: 1rem; }

figure {
	//align-items: center
	background: $grey-90;
	border-bottom: 1px solid $darkgrey;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	min-height: 80vh;
	padding: 4rem 2em;
	width: 100%;
	img {
		//box-shadow: 0 0 10px 0 rgba(0,0,0,0.25)
		max-width: 64em;
		margin: 0 auto;
		width: 100%; } }

figcaption {
	margin: 3rem auto 0;
	padding: 0;
	p {
		color: $off-white; }
	h2 {
		color: $off-white; } }


.text-left {
	display: grid;
	gap: 3em;
	grid-template-columns: 2fr 3fr;
	padding: 4em 12.5vw;
	figcaption {
		grid-column-start: 1;
		grid-row-start: 1;
		margin: 0;
		max-width: 100%;
		width: 100%; }
	img {
		grid-column-start: 2;
		max-width: 100%; } }


.text-right {
	display: grid;
	gap: 3em;
	grid-template-columns: 3fr 2fr;
	padding: 4em 12.5vw;
	figcaption {
		grid-column-start: 2;
		grid-row-start: 1;
		margin: 0;
		max-width: 100%;
		width: 100%; }
	img {
		grid-column-start: 1;
		max-width: 100%; } }

.text-center {
	display: grid;
	gap: 1em;
	grid-template-columns: 12.5vw 1fr 12.5vw;
	grid-template-rows: auto auto;
	min-height: 1px;
	div {
		grid-column: 2;
		grid-row: 1;
		img {
			max-width: 100%; } }
	figcaption {
		grid-column: 2;
		grid-row: 2;
		margin: 0 auto; } }

.double {
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto;
	figcaption {
		grid-column: 1 / 3;
		grid-row-start: 2;
		margin: 0 auto;
		max-width: 50rem; }
	img:first-of-type {
		grid-column-start: 1; }
	img:last-of-type {
		grid-column-start: 2; }
	h2 {
		margin-top: 0; } }

.triple {
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto;
	img:nth-of-type(1) {
		grid-column-start: 1; }
	img:nth-of-type(2) {
		grid-column-start: 2; }
	img:nth-of-type(3) {
		grid-column-start: 1;
		grid-row-start: 2; }
	figcaption {
		grid-column-start: 2;
		grid-row-start: 2; }
	h2 {
		margin-top: 0; } }

.phone {
	grid-template-columns: 1fr 2fr 2fr 1fr;
	figcaption {
		grid-column-start: 3; }
	div {
		grid-column-start: 2; } }
