@media (max-width: 767px) {
	//global styles
	.hidden-xs {
		display: none; }

	.visible-xs {
		display: block; }

	.mobile-center {
		text-align: center; }

	.flex-767 {
		display: flex; }

	.block-767 {
		display: block; }

	.uppercase.mobile-center {
		font-size: 1.25em; }

	.mt-0-mobile {
		margin-top: 0; }

	p, ul {
		font-size: 1.125rem; }

	p.small {
		font-size: 1rem; }

	p {
		margin-bottom: 1.5rem; }

	.slideshow {
		margin: 0; }

	footer {
		padding: 2rem 1rem 1rem; }

	.pull-right {
		float: none; }

	figure {
		display: flex;
		flex-direction: column;
		width: auto;
		img {
			margin: 0 auto; } }

	//portfolio banner
	.banner.inside-wrapper {
		justify-content: flex-start;
		margin: 0;
		max-width: 100%;
		&:before {
			content: none; }
		&:after {
			content: none; } }

	.banner {
		justify-content: left;
		.banner-text {
			text-align: left;
			z-index: 10; } }

	.inside-wrapper .banner-text {
		margin: 0 2rem; }

	.banner {
		//padding: 3rem
		h2 {
			margin-bottom: 0.25rem;
			margin-top: 0; }
		p {
			margin-bottom: 0.5rem; } }


	//banners
	.tall .banner-img {
		bottom: 0;
		left: 0;
		position: absolute;
		top: 0; }

	.right .callout-text {
		margin-left: 0;
		max-width: calc(100% - 4rem); }

	.banner-text p.cta, .tile p.cta {
		display: none; }

	.banner-overlap {
		margin: -2em 1em 0;
		max-width: calc(100vw - 2em);
		width: auto; }

	.callout {
		justify-content: left;
		margin: 0;
		padding: 0 5vw;
		width: 100%;
		&.negative-bottom {
			margin: 0; }
		&.negative-top {
			margin: 0; }
		&.left {
			justify-content: left; } }

	.callout.square {
		justify-content: flex-start;
		margin: 0;
		max-width: 100%;
		padding: 2rem; }

	.clip-left, .clip-right {
		clip-path: none;
		-webkit-clip-path: none; }

	.callout-img {
		@include bg-img-container;
		margin: 0;
		max-width: 100%;
		img {
			@include bg-img; } }

	.split-991.callout img, .split-991 .callout-img {
		max-width: 100%; }

	.callout-text {
		color: $white;
		margin: 0;
		max-width: calc(100% - 6rem);
		position: relative;
		text-align: left;
		z-index: 10;
		p {
			display: none; } }

	//Case study template
	.case-study-overview, .case-study-info {
		width: 100%; }

	.case-study-text h3 {
		font-size: 1.76em; }

	.case-study-info {
		display: flex;
		flex-wrap: wrap;
		.flex-col {
			width: 33.33%; } }

	//Slideshow
	.slideshow .flex-row img {
		height: auto;
		object-fit: none;
		max-width: 100%;
		position: static;
		width: auto; }

	.slideshow .ad {
		height: auto;
		padding: 2rem; }

	.slick-arrow {
		//display: none !important
		top: calc(37.5vw - 2.5rem); }

	.slick-previous {
		left: 0; }

	.slick-next {
		right: 0; }

	.slideshow-dots {
		flex-direction: row;
		left: 0;
		padding: 0 0.5rem;
		top: -0.25rem;
		width: 100%;
		li {
			padding: 0 0.33rem;
			width: auto;
			//width: 100%
			button {
				background: $black;
				border: 0 none;
				border-radius: 6px;
				box-shadow: 0 0 2px 0 rgba(0,0,0,0.66);
				height: 12px;
				width: 12px; } } }

	.slideshow-dots .slick-active button {
		background: $white;
		&:hover {
			background: $white; } }

	//About
	.flex-col {
		padding: 0;
		width: 100%; }

	.title-left, .content-right {
		display: block;
		float: none;
		padding: 0;
		text-align: left;
		width: 100%; }

	.content-right h3:first-of-type {
		margin-top: 0; }

	.content-right .flex-row {
		display: flex; }

	.main-form {
		padding: 0 0 2rem;
		position: relative;
		.shape-left {
			left: -2rem;
			height: 4em;
			top: -2rem; }
		.shape-right {
			height: 4em;
			right: -2rem; } }

	.form-wrapper {
		padding: 2rem;
		padding-bottom: 4rem; }

	//banners
	.half-h h2, .banner h2, .banner h1, .tile h2, .callout h2, .callout.square h2, .card-text h2 {
		//some math - calc(base + (max - min)*(100vw - min screen)/(max screen - min screen))
		font-size: calc(24px + (64 - 28.8)*(100vw - 540px)/(767 - 540)); }

	.half-w .text, .half-w .text, .callout .callout-text {
		max-width: 17em;
		text-align: left;
		width: 20em; }


	.full-h, .half-h, .callout.square, .callout, .banner.inside-wrapper {
		height: 40vh;
		min-height: 25em; }

	.banner-list li {
		display: block;
		&:after {
			content: ""; } }

	//modal
	.modal-container {
		max-height: calc(100% - 2rem);
		max-width: calc(100% - 2rem);
		min-width: 30rem;
		width: auto;
		h2 {
			font-size: 1.76em;
			margin-top: 0; }
		label {
			font-size: 1.125em; }
		header {
			display: inline-block;
			float: left;
			width: 33.33%; }
		input {
			padding: 0.75rem; }
		textarea {
			min-height: 6em;
			padding: 0.75rem; } }

	#modal-1-content {
		display: inline-block;
		float: left;
		width: 66.66%; }


	//More projects
	.case-study {
		height: 40vh;
		max-height: 25em;
		justify-content: flex-start;
		&.left {
			justify-content: flex-end; } }

	.case-study-text p {
		margin-bottom: 0rem; }

	.experience.wide-wrapper {
		max-width: 100%;
		margin-left: 0;
		margin-right: 0;
		padding: 0;
		.inner-caption {
			margin: 2rem 1rem 0;
			padding: 1rem 0; }
		width: 100%; }

	//card
	a .card-text {
		h2 {
			font-size: 1.25em; }
		p {
			font-size: 0.88em; } }

	.portfolio-wrapper {
		margin-top: 0;
		padding: 0;
		width: 100%; }

	.hero.tall .banner-text {
		min-width: 0; }

	.home-section {
		align-items: flex-end;
		margin: 0;
		min-height: 1px;
		width: 100%; }

	.home-section_text hr {
		margin: 1em 0; }


	.home-section_text {
		display: block;
		padding: 2em; }

	.top-nav {
		flex-direction: column;
		padding: 2em 1.5em; }

	.top-nav .nav-list {
		margin-top: 2em; }

	.home-section {
		border: 0 none;
		flex-direction: column;
		//margin: 1em 2em
 }		//width: calc(100% - 4em)

	.case-studies {
		grid-template-columns: 1fr; }

	.home-section_text, .home-section_img {
		width: 100%; }

	.home-section .home-section_text, .home-section:nth-of-type(even) .home-section_text {
		order: initial;
		max-width: 100%;
		h2 {
			font-size: 1.5em; } }

	.hp-intro {
		flex-direction: column;
		gap: 0.5rem;
		padding: 4em 2em;
		h1 {
			width: 100%; }
		p {
			font-size: 1.25em;
			margin: 0;
			width: 100%; } }

	footer h2 {
		font-size: 1em; }

	footer {
		padding: 2em; }

	footer .col-left {
		margin-bottom: 2em; }

	.text-left, .text-right {
		display: block; }

	figcaption {
		padding: 1.5em 0 0; }

	.about-wrapper {
		grid-template-columns: 1fr; }

	.photo-wrap {
		order: 0; }

	.logo-list {
		gap: 3em;
		grid-template-columns: 1fr 1fr 1fr;
		max-width: 32em; }

	.double {
		display: grid;
		gap: 1em;
		grid-template-columns: 1fr;
		grid-template-rows: auto auto auto;
		img:first-of-type {
			grid-column: 1;
			grid-row: 1; }
		img:last-of-type {
			grid-column: 1;
			grid-row: 2; }
		figcaption {
			grid-column: 1;
			grid-row: 3; } }

	figure.triple {
		display: grid;
		gap: 1em;
		grid-template-columns: 1fr;
		grid-template-rows: auto auto auto auto;
		img:nth-of-type(1) {
			grid-column: 1;
			grid-row: 1; }
		img:nth-of-type(2) {
			grid-column: 1;
			grid-row: 2; }
		img:nth-of-type(3) {
			grid-column: 1;
			grid-row: 3; }
		figcaption {
			grid-column: 1;
			grid-row: 4; } }

	.text-center {
		display: flex;
		flex-direction: column;
		padding: 0;
		figcaption {
			padding: 1.5em; } } }
