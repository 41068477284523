

.content-right h3:first-of-type {
    margin-top: 0.6rem; }

.job {
	display: flex; }

.job-logo {
	filter: grayscale(100%);
	padding-left: 2em;
	width: 33.33%;
	opacity: 0.33;
	img {
		max-height: 5em; } }

.job-description {
	width: 66.66%; }

.wrapper-80 {
	max-width: $wide-container;
	margin: 0 auto;
	position: relative;
	.title-left {
		width: calc(100% - 60em); }
	.content-right {
		width: 60em; } }

.logo-list {
	display: grid;
	font-size: 1rem;
	gap: 4em;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	list-style-type: none;
	margin: 0;
	padding: 0;
	li {
		align-items: center;
		display: flex;
		filter: grayscale(100%);
		mix-blend-mode: multiply;
		opacity: 0.7; } }

.about-wrapper {
	display: grid;
	gap: 3em;
	grid-template-columns: 3fr 1fr;
	margin: 3em auto 0;
	max-width: $wide-container;
	padding: 0 2em;
	.text-wrap {
		margin: 0; }
	h1 {
		color: $charcoal;
		font-size: 2.8em;
		font-weight: 600;
		margin-bottom: 0.5em;
		margin-top: 0.5em;
		width: 100%; } }


.photo-wrap {
	order: 1;
	text-align: center;
	img {
		max-width: 100%; }
	.unstyled-list li {
		font-size: 1rem;
		line-height: 0.9;
		margin: 1em 0 1em 0.25em;
		padding: 0 0.25em; } }

.photo-wrap .unstyled-list {
	display: flex;
	justify-content: center;
	li {
		border-right: 1.5px solid $grey-90; }
	li:last-of-type {
		border-right: 0 none; } }
