//Media queries
@media (max-width: 1200px) {
	.banner-overlap {
		margin-left: 3em;
		margin-right: 3em;
		max-width: calc(100% - 6em); }

	.shape-left {
		height: 20vw;
		left: -10vw; }

	.shape-right {
		height: 20vw;
		right: -10vw; }

	.case-study-text h3 {
		//some math - calc(base + (max - min)*(100vw - min screen)/(max screen - min screen))
		font-size: calc(32px + (96 - 48)*(100vw - 991px)/(1200 - 991)); }

	.banner.left {
		justify-content: flex-start; }

	.card-text {
		h2 {
			font-size: 1.875em; }
		p {
			font-size: 1em; } }

	.tall .banner-img {
		position: absolute; }

	.grid {
		display: block; }


	.case-studies {
		margin: 0 1.5em; }

	figure {
		min-height: 1px; }

	.text-right, .text-left {
		grid-template-columns: 1fr 1fr;
		padding: 4em; }

	figcaption h2 {
		margin-top: 0; }

	.tall {
		padding: 0;
		.background-img {
			height: auto;
			img {
				position: static; } }
		.banner-text {
			padding: 3em 2em; } }

	figure.triple {
		display: grid;
		gap: 1em;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto auto auto;
		img:nth-of-type(1) {
			grid-column: 1;
			grid-row: 1; }
		img:nth-of-type(2) {
			grid-column: 2;
			grid-row: 1; }
		img:nth-of-type(3) {
			grid-column: 3;
			grid-row: 1; }
		figcaption {
			grid-column: 1 / 4;
			grid-row: 2; } }

	.phone {
		div {
			grid-column-start: 1; }
		figcaption {
			grid-column-start: 2; } }


	footer .inside-wrapper {
		padding: 0; } }
