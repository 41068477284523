footer {
	border-top: 1px solid $lightgrey;
	padding: 3em;
	.inside-wrapper {
		display: flex;
		gap: 2em;
		justify-content: space-between;
		margin: 0 auto;
		max-width: $wide-container;
		padding: 0 2em;
		width: 100%; }
	p {
		color: $darkgrey;
		margin: 0;
		font-size: 1rem;
		line-height: 4; }
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0; }
	li {
		font-size: 1rem; }
	a {
		font-family: $primary-font; }
	h2 {
		margin: 0 0 0.25rem; } }

.contact {
	@include flex-center;
	list-style-type: none;
	margin: 0;
	padding: 0;
	a {
		border-bottom: 0 none;
		border-radius: 100%;
		display: block;
		height: 1.5em;
		line-height: 1;
		margin: 0 0.5em;
		width: 1.5em;
		&:focus {
			background: none;
			outline: 2px dotted $primary;
			outline-offset: 4px; } }
	svg {
		height: 1.5em;
		width: 1.5em;
		opacity: 0.24;
		&:hover {
			opacity: 0.48; } }
	.cta {
		padding: 0.5em 1.5em; } }

a[data-micromodal-trigger="modal-1"] {
	cursor: pointer; }
