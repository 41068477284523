@media (max-width: 540px) {
	.contact {
		flex-wrap: wrap;
		.cta {
			margin-top: 1rem; } }

	.block-540, .visible-540 {
		display: block; }

	.hidden-540 {
		display: none; }

	.case-study-info {
		display: block;
		.flex-col {
			width: 100%; } }

	.stat {
		width: 100%;
		.large {
			//some math - calc(base + (max - min)*(100vw - min screen)/(max screen - min screen))
			font-size: calc(16px + (96 - 32)*(100vw - 200px)/(540 - 200)); } }

	.link-list li {
		padding: 1rem 0; }

	.callout {
		max-width: 100%; }

	.content-right .flex-row {
		display: block; }

	footer {
		.inside-wrapper {
			display: block; }
		p {
			text-align: center; } }

	.contact {
		font-size: 1rem;
		margin: 0 auto;
		max-width: 18em;
		li {
			padding: 0; } }

	.contact a {
		margin: 0.5rem; }

	.contact a, .contact svg {
		height: 3rem;
		//margin: 1rem 2rem
		width: 3rem; }

	.main-wrapper {
		margin: inherit 1rem 0;
		padding: 0.75rem;
		width: calc(100% - 3rem); }

	.portfolio-wrapper {
		padding: 0; }

	.negative-offset {
		margin-top: -2rem; }

	.portfolio-wrapper.negative-offset {
		margin-top: 0;
		width: 100%; }

	//tiles
	.tile, .tall, .tile.left-flex {
		justify-content: flex-start;
 }		//padding: 3rem 2rem

	.half-h h2, .banner h2, .banner h1, .tile h2, .callout h2, .callout.square h2, .card-text h2 {
		//some math - calc(base + (max - min)*(100vw - min screen)/(max screen - min screen))
		font-size: calc(24px + (40 - 28.8)*(100vw - 200px)/(540 - 200));
		margin-bottom: 0.25rem; }

	.half-w .text, .tall .banner-text, .half-w .text, .callout .callout-text {
		//color: $white
 }		//max-width: 66vw

	.full-h, .half-h, .tall, .callout.square, .callout, .banner.inside-wrapper {
		//height: 33.33vh
 }		//min-height: 18em

	//removing bottom margins to get everything to line up nice on mobile
	.tile p, .banner p {
		margin-bottom: 0; }

	//modal
	.modal-container {
		min-width: 1px; }

	.modal-container .cta, button.cta, .button.cta {
		width: 100%; }

	.cta.inline {
		width: auto; }

	.experience {
		width: 100%;
		.cta {
			margin: 1rem 0; } }

	#modal-1-content, .modal-container header {
		display: block;
		float: none;
		width: 100%; }

	a .card-text {
		left: 2rem;
		max-width: 45%; }

	.home-section_text p {
		font-size: 1em; }

	.home-section_text .client-name {
		font-size: 0.875em; }

	.job-logo {
		width: 50%; }

	.job-description {
		width: 50%; }

	.portfolio-wrapper {
		width: 100%; }

	.photo-wrap {
		float: none;
		margin-left: 0; }

	.nav-list a {
		display: block;
		font-size: 0.875em;
		margin: 0;
		padding: 0 0.75rem; }

	.nav-list {
		justify-content: space-between;
		width: 100%; }

	.case-studies {
		margin: 0;
		padding: 00; }

	.home-section_text {
		padding: 1.5rem; }

	.logo-list {
		grid-template-columns: 1fr 1fr 1fr;
		gap: 2em; }

	footer {
		padding: 1.5em;
		li {
			margin: 1rem 0; } }

	.hp-intro {
		padding: 3em 1em; } }
