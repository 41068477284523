//utility classes
.main-wrapper {
	width: 100%;
	max-width: $container;
	margin: 0 auto;
	padding: 0 2em;
	position: relative;
	z-index: 10; }

.wide-wrapper {
	@extend .main-wrapper;
	max-width: $wide-container; }

.portfolio-wrapper {
	@extend .wide-wrapper;
	background: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 88rem;
	padding: 1rem;
 }	//margin-top: -5rem

.homepage-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 auto;
	max-width: $wide-container; }

.full-width {
	border-top: 1px solid $lightgrey;
	margin: 0;
	max-width: 100%;
	padding: 0;
	.inner-media {
		height: auto; } }

.negative-offset {
	box-shadow: 0 -8px 10px -10px rgba(0, 0, 0, 0.16);
	margin-top: -5em;
	padding: 2em;
	position: relative;
	z-index: 20;
	.banner {
		margin: 0 auto 2rem; } }

.block {
	display: block; }

.inline {
	display: inline; }

h2.small, h1.small {
	font-size: 2.5em; }

p.small {
	font-size: 1.125em; }

.align-left {
	text-align: left; }

.align-right {
	text-align: right; }

.align-center {
	text-align: center; }

.center-flex {
	justify-content: center; }

.left-flex {
	justify-content: flex-start; }

.modal-active {
	filter: blur(5px); }

.visible {
	display: block; }

.tablet-block, .tablet-flex, .hidden {
	display: none; }

.visible-540 {
	display: none; }

//for Chrome and Safari

.secondary {
	color: $secondary;
	display: block; }

.uppercase {
	color: $primary;
	text-transform: uppercase;
	//font-family: $primary-font
	font-weight: 700;
	font-size: 0.88em;
	letter-spacing: 1px; }

.scroll-lock, .scroll-lock body {
	height: 100%;
	overflow: hidden;
	overflow-y: -moz-hidden-unscrollable;
	overflow-y: hidden;
	.top-nav {
		position: fixed; } }

.flex-col {
	padding: 0 8px;
	width: 50%; }

.unstyled-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	li {
		padding: 0;
		line-height: 1.5; } }

*[data-toggle="modal"] {
	cursor: pointer; }

.row:after {
	clear: both;
	content: "";
	display: table; }

.title-left {
	display: inline-block;
	float: left;
	padding-right: 1em;
	text-align: right;
	width: calc(100% - 40em);
	h2 {
		margin-top: 0; } }

.content-right {
	display: inline-block;
	float: left;
	max-width: 100%;
	width: 40em; }

.error {
	padding: 25vh 0; }

//margin utilities
.mb-64 {
	margin-bottom: 4em; }

.mt-0 {
	margin-top: 0; }

.mb-0 {
	margin-bottom: 0; }

.mb-48 {
	margin-bottom: 3em; }

.mb-32 {
	margin-bottom: 2em; }

.mt-48 {
	margin-top: 3rem; }

//padding utilities
.no-padding {
	padding: 0; }

.ptb-0 {
	padding-bottom: 0;
	padding-top: 0; }

.pb-0 {
	padding-bottom: 0; }

.p3 {
	padding: 4em; }

.hp-intro {
	display: flex;
	gap: 3em;
	max-width: $wide-container;
	padding: 8em 2em;
	p {
		font-size: 1.5em;
		font-weight: 400;
		width: 66.66%; }
	h1 {
		font-size: 3.5em;
		font-weight: 900;
		letter-spacing: -0.05em;
		width: 33.33%; } }

figcaption {
	font-size: 1.125em;
	max-width: $container; }

//grid
.grid {
	display: grid;
	grid-column-gap: 3em;
	grid-template-columns: 1fr 2fr;
	margin-top: 4em;
	max-width: 72em;
	* {
		align-self: end;
		grid-column-start: 2; }
	h2 {
		align-self: start;
		font-size: 3em;
		grid-column-start: 1;
		margin-top: 0; }
	h2:not(first-of-type) {
		margin-top: 3rem; }
	h2 + p {
		margin-top: 3em; }
	figure {
		margin: 3em 0;
		max-width: 100%;
		width: 100%; }
	blockquote {
		max-width: 100%; }
	hr {
		align-self: start;
		border-color: $lightgrey;
		grid-column: 1 / span 2;
		margin: 3em 0 0;
		width: 100%; } }
