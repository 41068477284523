//banners
.banner {
	@include flex-center;
	border: 0 none;
	margin-bottom: 3em;
	width: 100%;
	.cta {
		color: $charcoal; }
	&:hover {
		color: inherit;
		.cta {
			color: $primary; } }
	p:last-of-type {
		margin-bottom: 0; } }

a.banner {
	img {
		transition: all 600ms ease; }
	&:hover img {
		transform: scale(1.1); } }

div.no-hover:hover img {
	transform: scale(1); }


.banner {
	h1 {
		color: $charcoal; }
	figcaption:before {
		content: none; } }


.inside-wrapper {
	max-width: $container;
	margin: 2em auto;
	.banner-text {
		margin-left: 4em; } }

.banner p, .callout p {
	color: inherit; }

.banner a, .callout a {
	//text-transform: uppercase
	font-family: $secondary-font;
	font-weight: 700;
	font-size: 1;
	//letter-spacing: 0.0125em
	color: $charcoal;
	text-decoration: none;
	border-bottom: 5px solid $primary;
	transition: all 200ms cubic-bezier(0.4, 0.0, 0.2, 1);
	&:hover {
		color: $primary; } }

.tall .cta {
	color: $white;
	display: inline-block;
	margin: 2rem 0;
	&:hover {
		color: $white; } }

.hero.tall .banner-text {
	left: -32em;
	min-width: 32.75em;
	width: auto; }

.hero.center {
	justify-content: center;
	padding: 0;
	.banner-text {
		margin-left: 0; } }

.banner.right {
	.banner-text {
		left: 25vw; } }

.banner.left {
	padding: 0 5vw; }

.banner-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	li {
		display: inline-block;
		&:after {
			color: $charcoal;
			content: " | ";
			font-weight: 400; }
		&:last-of-type:after {
			content: ""; }
		a {
			font-size: 0.88rem; } } }

//banner sizes
.tall {
	@include flex-center;
	background: none;
	border: 0 none;
	flex-direction: column;
	height: auto;
	margin: 0 auto 3em;
	max-width: $wide-container;
	padding: 0 2em;
	width: 100%;
	.banner-img {
		height: 100%;
 }		//z-index: 2
	.banner-text {
		padding: 3em 0;
		width: 100%;
		p {
			color: $primary; } }
	&:hover {
		color: inherit;
		.cta {
			color: $white; } }
	img {
		max-width: 100%;
		width: 100%; } }

.fade {
	opacity: 0.9; }

.close {
	background: none;
	border: 0 none;
	color: white;
	font-family: $secondary-font;
	font-size: 1em;
	padding: 1em 2em;
	position: fixed;
	right: 3em;
	text-decoration: underline;
	top: 3em;
	z-index: 10;
	&:hover {
		background: rgba(0,0,0,0.16);
		background: {} } }
