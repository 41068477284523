.modal[aria-hidden='true'] {
	display: none; }


@keyframes mm-fadeIn {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }

@keyframes mm-slideIn {
	0% {
		transform: translateY(-35%); }
	100% {
		transform: translateY(0); } }


.micromodal-slide[aria-hidden="false"] .curtain {
	animation: mm-fadeIn 300ms cubic-bezier(0.0, 0.0, 0.2, 1); }


.micromodal-slide[aria-hidden="false"] .modal-container {
	animation: mm-slideIn 300ms cubic-bezier(0, 0, .2, 1); }


.micromodal-slide .modal-container, .micromodal-slide .curtain {
	will-change: transform; }






.curtain {
	@include flex-center;
	background: rgba(255,255,255, 0.66);
	bottom: 0;
	cursor: pointer;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 100; }

.modal-container {
	max-width: 63em;
	background: $white;
	box-shadow: 0 0 20px 0 rgba(0,0,0,0.33);
	cursor: default;
	padding: 2em;
	position: relative;
	margin: 0 auto;
	h2 {
		color: $secondary;
		font-size: 1.76em;
		margin-left: 12rem; }
	p {
		margin-left: 12rem; }
	.cta {
		margin-left: 12rem; } }

.input-row {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 1em; }

.modal-close {
	background: none;
	border: 0 none;
	height: 3rem;
	opacity: 0.66;
	padding: 1rem;
	position: absolute;
	right: 0;
	top: 0;
	transition: all 300ms ease;
	width: 3rem;
	&:hover {
		opacity: 1; }
	img {
		height: 1rem;
		width: 1rem; } }
