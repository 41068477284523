.case-studies {
	display: grid;
	flex-wrap: wrap;
	gap: 1.5em;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	margin: 0 auto 3em;
	max-width: $wide-container;
	padding: 0 2em; }

.home-section {
	color: $charcoal;
	display: flex;
	flex-direction: column;
	margin: 1em 0;
	overflow: hidden;
	position: relative;
	text-decoration: none;
	width: 100%;
	z-index: 1;
	&:hover h2 {
		color: $primary; }
	&:hover .pointer {
		text-decoration: underline; } }

.home-section:hover, .home-section:focus {
	border-bottom: 0 none;
	.home-section_text {
		opacity: 1; } }

.home-section:focus, .home-section a:focus {
	outline: 2px dotted $primary;
	outline-offset: 2px; }

.home-section.white-bg {
	background: $white; }

.home-section_img {
	//@include bg-img-container
	background: $lightgrey;
	//height: 22vw
	//max-height: 15em
	overflow: hidden;
	position: relative;
	width: 100%;
	z-index: 1;
	img {
		//@include bg-img
		transition: opacity 100ms ease-in; } }



.home-section_text {
	opacity: 1;
	padding: 2em 0;
	text-align: left;
	transition: opacity 100ms ease-in;
	z-index: 1;
	h2 {
		color: $charcoal;
		font-size: 1.5em;
		font-weight: 600;
		letter-spacing: 0px;
		margin: 0 0 0.5rem;
		text-transform: none; }
	p {
		font-size: 1rem;
		line-height: 1.66;
		margin: 0 0 0.5em; }
	&:hover {
		color: $primary; }
	.pointer {
		color: $primary;
		font-family: $primary-font; } }

.client-name {
	display: inline-block;
	font-family: $secondary-font;
	font-size: 0.875em;
	letter-spacing: 1px;
	margin-bottom: 0;
	text-transform: uppercase;
	z-index: 1; }

.home-section_text hr {
	background-color: $primary;
	border: 0 none;
	height: 3px;
	margin: 1em 0;
	width: 6em; }

.stuck {
	position: fixed;
	top: 0; }

.scroll-affordance {
	align-items: center;
	background: rgba(0,0,0,0.33);
	border: 2px solid white;
	border-radius: 50%;
	bottom: 2em;
	display: flex;
	height: 3em;
	left: calc(50% - 2em);
	margin: 0 auto;
	position: fixed;
	width: 3em;
	transition: all 140ms cubic-bezier(0.4, 0.14, 0.3, 1);
	z-index: 10;
	svg {
		height: 1.5em;
		margin: 4px auto 0;
		width: 1.5em; } }

.flip-y {
	transform: scaleY(-1); }
