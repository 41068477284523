.slideshow {
	margin: 3em 0 0;
	position: relative;
	width: 100%; }

.slideshow-inner {
	position: relative;
	z-index: 1; }

.inner-caption {
	background: $charcoal;
	color: $white;
	margin: 0;
	padding: 2rem;
	p {
		color: $white;
		margin: 0 auto;
		max-width: $small-container;
		&.mb-32 {
			margin-bottom: 2em; } } }

.inner-media {
	align-content: center;
	align-items: center;
	background: $charcoal;
	display: flex;
	flex-wrap: wrap;
	height: 80vh;
	justify-content: center;
	padding: 3em 0;
	width: 100%;
	img {
		height: auto;
		max-height: 100%;
		max-width: 100%;
		width: auto; }
	.flex-row {
		flex-basis: 100%;
		margin-bottom: 2rem; }
	.flex-col {
		height: 100%;
		padding: 0 0.5rem;
		width: auto; } }

.inner-media.two-up {
	overflow: hidden;
	padding: 2rem calc(50vw - 30rem);
	@include flex-center(); }

$arrow-size: 4rem;

.slick-arrow {
	background: none;
	border: 0 none;
	color: $white;
	cursor: pointer;
	height: $arrow-size;
	line-height: $arrow-size;
	position: absolute;
	top: calc(80vh - 6rem);
	width: $arrow-size;
	z-index: 10;
	img {
		height: $arrow-size;
		opacity: 0.66;
		width: $arrow-size; }
	&:hover img {
		opacity: 1; } }

.slick-prev {
	left: 2rem; }

.slick-next {
	right: 2rem; }

.slideshow-dots {
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	left: 3rem;
	margin: 0;
	position: absolute;
	top: calc(40vh - 4rem);
	width: 1rem;
	li {
		list-style-type: none;
		padding: 0;
		button {
			background: $darkgrey;
			border: 0 none;
			border-radius: 50%;
			box-shadow: 0 0 4px 0 rgba(0,0,0,0.16);
			font-size: 0;
			height: 1rem;
			line-height: 0.75rem;
			margin: 0 0.5rem;
			opacity: 0.66;
			padding: 0;
			width: 1rem;
			&:hover {
				opacity: 1; }
			&:focus {
				outline: 0; }
			&::-moz-focus-inner {
				border: 0;
				outline: 0; } } }
	.slick-active button {
		background: $white;
		&:hover {
			background: $white; } } }

.inner-media img {
	max-width: $wide-container; }
