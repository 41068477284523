//top navigation

header {
	border-bottom: 1px solid $lightgrey; }

.top-nav {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	max-width: $wide-container;
	padding: 2em;
	width: 100%;
	ul {
		display: flex;
		list-style-type: none;
		margin: 0;
		padding: 0; }
	li {
		margin: 0;
		padding: 0; } }

.top-nav a {
		border: 0 none;
		&:focus {
			background: none;
			.logo-path {
				fill: $secondary; } } }

.nav-list {
	a {
		border: 0 none;
		color: $charcoal;
		font-family: $primary-font;
		font-size: 1rem;
		font-weight: 400;
		margin: 0 0.5em;
		padding: 0.25em 0.5em;
		text-decoration: none;
		transition: all 50ms cubic-bezier(0.4, 0.0, 0.2, 1);
		&:hover {
			color: $primary; }
		&:focus {
			background: none;
			color: $secondary;
			outline: 2px dotted $primary; } }

	a.active {
		//border-bottom: 3px solid $primary
		color: $primary;
		font-weight: 700; } }

.top-nav-img {
	height: 3rem;
	width: 3rem;
	&:before {
		bottom: -0.5rem;
		left: -0.5rem;
		position: absolute;
		right: -0.5rem;
		top: -0.5rem; } }

.logo-path {
	fill: $primary; }

.white-path {
	fill: $white; }

.mobile-close {
	@include flex-center;
	background: 0;
	border: 0;
	padding: 1rem;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 10;
	&:hover .white-path {
		fill: $tertiary; }
	.top-nav-img {
		height: 2rem;
		width: 2rem; } }

.home-nav {
	position: fixed; }

.top-nav > a:first-of-type {
	height: 3rem;
	width: 3rem;
	&:focus {
		outline-offset: 4px; } }
