.more {
	align-items: flex;
	display: flex;
	justify-content: space-around;
	position: relative; }

a.case-study {
	@include flex-center;
	border: 0 none;
	overflow: hidden;
	padding: 2rem;
	position: relative;
	width: 100%;
	&.left {
		margin-left: 0; }
	img {
		@include bg-img;
		transition: all 600ms ease; }
	&:hover img {
		transform: scale(1.1); }
	.background-img {
		@include bg-img-container; } }

.case-study-text {
	color: $white;
	max-width: 75%;
	position: relative;
	z-index: 2;
	h3 {
		font-family: $primary-font;
		font-size: 3em;
		font-weight: 700;
		margin: 0; }
	p {
		margin-top: 0.5em; }
	&:hover {
		color: $white;
		.cta {
			color: $primary; } }
	.cta {
		color: $white; } }

.left {
	justify-content: flex-end;
	.case-study-text {
		text-align: right; } }

.right {
	justify-content: flex-start;
	.case-study-text {
		text-align: left; } }

.tag-left {
	align-items: center;
	background: $primary;
	color: $white;
	display: flex;
	-webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 66.66% 100%);
	clip-path: polygon(0 100%, 0 0, 100% 0, 66.66% 100%);
	font-family: $primary-font;
	font-size: 0.88rem;
	font-weight: 700;
	height: 8rem;
	justify-content: center;
	left: 0;
	padding-right: 2em;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	top: -2rem;
	width: 11rem;
	z-index: 10; }

.tag-right {
	align-items: center;
	background: $secondary;
	bottom: -2rem;
	color: $white;
	display: flex;
	-webkit-clip-path: polygon(33.33% 0, 0 100%, 100% 100%, 100% 0);
	clip-path: polygon(33.33% 0, 0 100%, 100% 100%, 100% 0);
	font-family: $primary-font;
	font-size: 0.88rem;
	font-weight: 700;
	height: 8rem;
	justify-content: center;
	padding-left: 2em;
	position: absolute;
	right: 0;
	text-align: center;
	text-transform: uppercase;
	width: 11rem;
	z-index: 10; }
