$white:     #FFFFFF;
$off-white: #dee2e6;
$charcoal:  #1a1a1a;
$grey-90:   #212529;
$black:     #1a1a1a;
//$primary:   #169BE2 RIP original blue
$primary:   #0476D9;
$secondary: #034C8C;
$tertiary:  #023059;
$blue-90:   #091326;
$lightgrey: #e4e4e1;
$darkgrey:  #686666;

//Units of measure
$small-container: 45em;
$container: 50em;
$wide-container: 75em;


//$secondary-font: 'M PLUS 1p', 'Helvetica Neue', 'Helvetica', 'Segoe UI', 'Arial', sans-serif
//$primary-font: 'M PLUS 1p', 'Helvetica Neue', 'Helvetica', 'Segoe UI', 'Arial', sans-serif
$secondary-font: 'Oxygen', 'Helvetica Neue', 'Helvetica', 'Segoe UI', 'Arial', sans-serif;
$primary-font: 'Oxygen', 'Helvetica Neue', 'Helvetica', 'Segoe UI', 'Arial', sans-serif;
$tertiary-font: 'Oxygen', 'Helvetica Neue', 'Helvetica', 'Segoe UI', 'Arial', sans-serif;

@mixin bg-img-container() {
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0; }

@mixin bg-img() {
	bottom: 0;
	font-family: "object-fit: cover";
	height: 100%;
	left: 0;
	max-height: none;
	max-width: none;
	//min-width: 50%
	object-fit: cover;
	position: absolute;
	width: 100%;
	right: 0;
	top: 0; }

@mixin flex-center() {
	align-items: center;
	display: flex;
	justify-content: center; }
